import React from "react"
import styled from "styled-components"

const AdditionalMessageComponent = (props) => {
  const { messageData, dataToSend, message, setMessage, additionalMessage } = props
  const [messageActive, setMessageActive] = React.useState(false)

  if (!messageData || !additionalMessage) {
    return null
  }

  function handleButtonClick() {
    setMessageActive(!messageActive)
  }

  React.useEffect(() => {
    dataToSend.Message = message
  }, [message])

  return (
    <>
      <DispatchDetail>
        <SmallPara className="add-margin">{additionalMessage}</SmallPara>
        <div>
          <Checkbox onClick={handleButtonClick} className={`${messageActive ? "active" : ""}`} />
        </div>
      </DispatchDetail>
      {messageActive && (
        <StyledTextarea
          key="additional-text-message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></StyledTextarea>
      )}
    </>
  )
}

export default React.memo(AdditionalMessageComponent)

const Checkbox = styled.button`
  width: 24px;
  min-height: 24px;
  display: block;
  background: transparent;
  border: solid 2px #fff;

  &:hover {
    cursor: pointer;
  }

  &.active {
    &:after {
      content: "\\2714";
      display: block;
      color: #fff;
    }
  }
`

const DispatchDetail = styled.div`
  display: flex;
  align-self: flex-end;
  width: auto;
  max-width: 80%;
  margin-left: auto;
  margin-top: 20px;
  justify-content: flex-end;

  p {
    margin-right: 10px;
  }
`

const StyledTextarea = styled.textarea`
  display: flex;
  align-self: flex-end;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-top: 20px;
  justify-content: flex-end;
`

const SmallPara = styled.p`
  font-size: 12px;
  margin-top: -12px;

  &.add-margin {
    margin-top: 0;
  }
`
