import React from "react"
import styled from "styled-components"
import _, { first, forEach } from "lodash"
import { GoogleSpreadsheet } from "google-spreadsheet"
import { formatCompanyName } from "../utils/utilities"

import Logo from "../images/logo.webp"
import HeroTwo from "../images/hero-2.webp"
import AdditionalMessage from "./AdditionalMessage"

// Spreadsheet stuff...
const privateKey =
  "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDrkXSX+aZq3QJA\n4On+UwiV5+mL8bIzDCt7HorrQlVJYU1mltnQdeIOgEqZggA+56/3LSylS5l7s0FN\nDMPuXGlM2QOO48p9NPbFlthweZyJRl9aLgcp4K0EAY/eeaBLRyUqOg6uvF2KL9fH\nnYBiOvlZwH5tuwDo30tDNkXrSpOUjh0r4oRbfpiXzcYllkwVdwvVg+XQyqlUB9H3\nyTPYYP+TpQyO9TLMUSnIRkavyY0S0SAYFGal/rVjrkRkt5aaiJUAlTn/hQt8LRN3\noG/nJbggbt2zGByDicfWY38lxVU26AN3m6gMrLzJXbxjUvh3gmx4dlAzfieZn7Wc\n6HboXtbdAgMBAAECggEABmM3GqQlR5JjmgoM9R2NtLAJY4chdvHkLV5Dk+zNd6iz\nI/LuNcg2z3/+oUf5EruX/x4uijwH6S/ZN4WRTj0bZP8hKO1hCk7SIwx3JP0M6hMl\nyS+2wQNyi0l6sttwbuofT111OORz93E2PU5a8sXLCPo1d/2IDtrA5XsrDTAx/4vQ\nyagiqwvp4FZv8w+70FAW5M7MO2AIfTHewOxczwPP+PrxVvsEJtkKGzHjChsrKK4s\nBJ4g+CD04Z7kw5Sk6wwEyctnxmqD5Qi9y3bec/J5x55RgoGfG3hrgfiBH+3cHu0l\nC3yQ97Lt2Z6TvDeWMEEl1r2I8sZuO0CSSiF9muPjUQKBgQD5QX63xkbJCWZr1tit\nWwbRpo4JO0IIbKx8o8M8B50OqGQD8ZH8Q9mKSOLObQErI+89LXujVI00YS0Hjl8v\nqdAWCqEidw86H/aqoK0P7BMPkthjDhXWq1m1GF5KwUnK5uUDTKE5gfYbm5dtC9EP\nZECcJLQUJz/Q0FvZgVOJsaIqtQKBgQDx8SapasAhglxSZ7qU+iVuit9HtPjWexOu\nvDYfK8rJHfQslrWqrvG1N2wz8qgtrsU4poTRr8/B0GpmjkyPCGAxJ9TFII2arvGL\nP/8LkbHsEk1E7RnUlOi8tvW0auKTPnhz3jsujXHdt44oqUZ618ZVPxLmZvxz1Bup\nbAH0Si+MiQKBgQCQFmFnYRI4UtvHSSp2hCFb/nkrMvtIHXJUluJciFYtTACNGw92\nEc7mlp2uEOtVAe/hoLJRR6Zc+jPqo025IuQsabaeUs8VnbadCkcg5ipanVvDXzgW\noXU5L74KXtQGnBUVJC4rLUqUyQvvOJ6A1EZZPQpwgAPu9ROzl4QwG+SP5QKBgHUl\nAkc9fNG8wN7Vgq3ngIBJlVqu8V1IMnuEfeAR4BoI+tUsE8IU+st3jliIwY189dk8\nJ0swCTQaqaoGdGM89vZWBtt7q5Sd6C44SlFbtf5LzkX1V3BquVXGcKlup04MDV5s\n19LugZeM7LQbYhjQqSNVat1MOCgNJ2ec+EVENkmhAoGBAOYZv3KkV8sjTVA3S/7l\nJn3mj3bYkwQ2Hz67uhMH0DJWXiB6Aj/jSrcTJa1jLjrIaD5HZjGXB9SU80n4/MIY\nqnd9wmaC4XR9e/TuxNcufh8SLt9woSPjHmGCQLN10kscmeJXwHsJJKWNjiOLYJmG\nyvzpdIrHtpWX0ZawT9nIuXnM\n-----END PRIVATE KEY-----\n"
const SPREADSHEET_ID = "1kb4ruk-LRFC31_ZcjrMDwXYnEbtF1XY3YVgLIvEveig"
const CLIENT_EMAIL = "wzis-263@wzis-ordering.iam.gserviceaccount.com"
const PRIVATE_KEY = privateKey.replace(/\n/g, "\n")
const API_KEY = "AIzaSyCC81SOYvab29JBkBQessQkg2jsu4wicd4"
const doc = new GoogleSpreadsheet(SPREADSHEET_ID)

const TotalBox = (props) => {
  const {
    productDetails,
    discounts,
    postage,
    useForm,
    companyDetails,
    setShowOverlay,
    showOverlay,
    confirmation,
    firstDiscountTier,
    messageData,
    audiencePath,
    activeCompanyAudience,
    existingCompany,
    companyFilter
  } = props
  const [seconds, setSeconds] = React.useState(0)
  const [productsTotal, setProductsTotal] = React.useState(0)
  const [productsTotalWithoutDiscount, setProductsTotalWithoutDiscount] = React.useState(0)
  const [discountTiers, setDiscountTiers] = React.useState()
  const [postageTiers, setPostageTiers] = React.useState()
  const [finalDiscount, setFinalDiscount] = React.useState(0)
  const [totalWithDiscount, setTotalWithDiscount] = React.useState(0)
  const [finalPostage, setFinalPostage] = React.useState(0)
  const isDisabled = useForm ? !companyDetails.company : false
  const [endDiscount, setEndDiscount] = React.useState(0)
  const [isActive, setIsActive] = React.useState(false)
  const [message, setMessage] = React.useState()

  // Message data
  const afterMessage = messageData["Add message"]
  const totalBoxMessage = messageData["Box message"]
  const basketMessage = messageData["Basket total"]
  const volumeMessage = messageData["Volume discount"]
  const tierMessage = messageData["Tier message"]

  // Confirmation data
  const confirmationMessage = confirmation["Message"]
  const dispatchShow = confirmation["Show automatic dispatch"]
  const dispatchMessage = confirmation["Dispatch message"]
  const additionalMessage = confirmation["Additional Message"]
  const inputRef = React.useRef()

  // Get the company name
  let search = window.location.search
  let params = new URLSearchParams(search)
  let companyName = params.get("company")

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }

    handleNewCompany()
  }, [])

  // Data to send
  let dataToSend = {}

  React.useLayoutEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1)
      getTotal()
    }, 500)

    setDiscountTiers(getDiscountTiers())
    setPostageTiers(getPostageTiers())

    return () => clearInterval(interval)
  }, [])

  async function handleNewCompany() {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY
    })
    await doc.loadInfo() // loads document properties and worksheets
    const companiesSheet = doc.sheetsById[604849251]

    // This checks for the existing company check used in App.js as well as checking new
    // form submissions with the companies list in the google sheets.
    if (existingCompany === false) {
      let dontAllowCompany

      companyFilter &&
        companyFilter.map((item) => {
          if (item["Company name"] === companyDetails.company) {
            dontAllowCompany = true
          }
        })

      if (!dontAllowCompany) {
        companiesSheet.addRow({
          "Company name": useForm ? companyDetails.company : formatCompanyName(companyName)
        })
      }
    }
  }

  function getDiscountTiers() {
    let discountsArray = []
    discounts.map((item, index) => {
      // We set the discount tier to the same as the audience path
      // If there is no audience path, we set it to be the default discount percentage.
      if (item["Discount tier"] && _.get(item, activeCompanyAudience)) {
        discountsArray.push({
          tier: Number(item["Discount tier"]),
          discount: Number(item[activeCompanyAudience])
        })
      } else if (item["Discount tier"] && _.get(item, audiencePath)) {
        discountsArray.push({
          tier: Number(item["Discount tier"]),
          discount: Number(item[audiencePath])
        })
      } else if (item["Discount tier"] && item["Discount percentage"]) {
        discountsArray.push({
          tier: Number(item["Discount tier"]),
          discount: Number(item["Discount percentage"])
        })
      }
    })

    const filteredArray = discountsArray.filter(function(value) {
      return !Number.isNaN(value)
    })

    return filteredArray
  }

  function getPostageTiers() {
    let postageArray = []
    postage.map((item, index) => {
      if (item["Tier"] && _.get(item, activeCompanyAudience)) {
        postageArray.push({
          tier: Number(item["Tier"]),
          price: Number(item[activeCompanyAudience])
        })
      } else if (item["Tier"] && _.get(item, audiencePath)) {
        postageArray.push({
          tier: Number(item["Tier"]),
          price: Number(item[audiencePath])
        })
      } else if (item["Tier"] && item["Price"]) {
        postageArray.push({
          tier: Number(item["Tier"]),
          price: Number(item["Price"])
        })
      }
    })

    const filteredArray = postageArray.filter(function(value) {
      return !Number.isNaN(value)
    })

    return filteredArray
  }

  function getTotal() {
    let productTotal = 0
    let productWithoutDiscountTotal = 0
    productDetails.map((item) => {
      if (item && item.quantity) {
        const newProduct = item.quantity * Number(item.tradeCost)
        const newProductNoDiscount = item.quantity * Number(item.price / 1.2)

        productTotal = productTotal + newProduct
        productWithoutDiscountTotal = productWithoutDiscountTotal + newProductNoDiscount
      }
    })
    setProductsTotal(productTotal)
    setProductsTotalWithoutDiscount(productWithoutDiscountTotal)
  }

  async function handleSubmit() {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY
    })
    await doc.loadInfo() // loads document properties and worksheets
    const sheet = doc.sheetsById[1304132677] // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
    const companiesSheet = doc.sheetsById[604849251]

    // Get all the prodducts with quantity
    let orderDetails = []
    productDetails.map((item) => {
      if (item.quantity > 0) {
        const itemDetails = `${item.name} (${item.packQTY}): ${item.additionalDiscount}% (line discount) - ${item.quantity} ordered \n`
        orderDetails.push(itemDetails)
      }
    })

    // Format data to send to sheets.
    dataToSend = {
      ...dataToSend,
      Company: useForm ? companyDetails.company : formatCompanyName(companyName),
      Contact: useForm ? companyDetails.contact : "",
      Telephone: useForm ? companyDetails.telephone : "",
      Email: companyDetails.email,
      "Address 1": useForm ? companyDetails.addressone : "",
      "Address 2": useForm ? companyDetails.addresstwo : "",
      Town: useForm ? companyDetails.town : "",
      Postcode: useForm ? companyDetails.postcode : "",
      "Other notes": useForm ? companyDetails.notes : "",
      "Order details": orderDetails.join("").trim(),
      Subtotal: productsTotalWithoutDiscount.toFixed(2),
      Discount: endDiscount,
      "Total discount": `£${(
        productsTotalWithoutDiscount -
        productsTotalWithoutDiscount * (endDiscount * 0.01)
      ).toFixed(2)}`,
      Postage: finalPostage,
      Total: (productsTotalWithoutDiscount * (1 - endDiscount * 0.01) + finalPostage).toFixed(2),
      "Immediate dispatch": isActive,
      Message: message ? message : ""
    }

    // Add row to sheets with data to send
    sheet.addRow(dataToSend)

    // Show the overlay
    setShowOverlay(true)
  }

  const DiscountComponent = (props) => {
    const { discountTiers, setFinalDiscount } = props
    if (!discountTiers) {
      return null
    }

    let discount = 0
    let totalDiscount = 0
    let tierDifference
    let nextTierDiscount

    discountTiers.map((item, index) => {
      if (productsTotal >= item.tier) {
        // We get the discount from the discount tier and we also get the index
        discount = item.discount
        totalDiscount = item.discount

        // Get the tier difference

        // We check for the next tier and calculate the difference if there is no next tier, it's none.
        if (discountTiers[index + 1]) {
          // Get the next tier difference
          tierDifference = discountTiers[index + 1].tier - productsTotal

          // Get the next tier discount
          nextTierDiscount = discountTiers[index + 1].discount
        } else {
          nextTierDiscount = "none"
        }
      }
    })

    // Remove the first tier of discount from the discount
    discount = discount - firstDiscountTier

    React.useEffect(() => {
      // Sets the final discount by getting the discount percentage
      setFinalDiscount(discount)

      // Gets the total with discount
      if (discount !== 0) {
        setTotalWithDiscount(productsTotal - productsTotal * (discount * 0.01))
      } else {
        setTotalWithDiscount(productsTotal)
      }
    }, [discountTiers])

    setEndDiscount(totalDiscount)

    return (
      <>
        <StyledPara>
          <span>
            {volumeMessage} ({discount}%)
          </span>
          - £{(productsTotalWithoutDiscount * (discount * 0.01)).toFixed(2)}
        </StyledPara>
        <StyledHr />
        <StyledPara>
          <span>Order total</span> £
          {(productsTotalWithoutDiscount - productsTotalWithoutDiscount * (totalDiscount * 0.01)).toFixed(2)}
        </StyledPara>
        <SmallPara>Total Discount: {totalDiscount}%</SmallPara>
        <PostageComponent postageTiers={postageTiers} setFinalPostage={setFinalPostage} />
      </>
    )
  }

  const DiscountTiersComponent = (props) => {
    const { discountTiers, setFinalDiscount } = props
    if (!discountTiers) {
      return null
    }

    let discount = 0
    let activeIndex = 0
    let tierDifference
    let nextTierDiscount

    discountTiers.map((item, index) => {
      if (productsTotal >= item.tier) {
        discount = item.discount
        activeIndex = index
      }

      const isLargeNumber = (element) => element.discount > discount
      const discountIndex = discountTiers.findIndex(isLargeNumber)

      if (discountIndex !== -1) {
        tierDifference = discountTiers[discountIndex].tier - productsTotal
        nextTierDiscount = discountTiers[discountIndex].discount
      } else {
        nextTierDiscount = "none"
      }
    })

    React.useEffect(() => {
      // Sets the final discount by getting the discount percentage
      setFinalDiscount(discount)

      // Gets the total with discount
      if (discount !== 0) {
        setTotalWithDiscount(productsTotal - productsTotal * (discount * 0.01))
      } else {
        setTotalWithDiscount(productsTotal)
      }
    }, [discountTiers])

    return (
      <>
        {tierDifference && nextTierDiscount && nextTierDiscount !== "none" && (
          <>
            <SmallPara>{firstDiscountTier}% standard discount</SmallPara>
            <SmallPara>
              {afterMessage} £{tierDifference.toFixed(2)} {tierMessage} {nextTierDiscount - discount}%
            </SmallPara>
          </>
        )}
        {nextTierDiscount === "none" && (
          <p>
            <StyledItalic>You're getting the best savings.</StyledItalic>
          </p>
        )}
      </>
    )
  }

  const PostageComponent = (props) => {
    const { postageTiers, setFinalPostage } = props
    if (!postageTiers) {
      return null
    }

    let price = postageTiers[0].price

    postageTiers.map((item, index) => {
      if (productsTotal > item.tier) {
        price = item.price
      }
    })

    const isZero = (element) => element.price === 0
    const isFree = postageTiers.findIndex(isZero)
    const freePrice = postageTiers[isFree].tier

    React.useEffect(() => {
      setFinalPostage(price)
    }, [postageTiers])

    return (
      <>
        <StyledPara>
          <span>
            <StyledItalicSmall>
              {totalBoxMessage && totalBoxMessage} £{freePrice})
            </StyledItalicSmall>
          </span>
          <span>£{price.toFixed(2)}</span>
        </StyledPara>
      </>
    )
  }

  const DispatchComponent = (props) => {
    const { messageData } = props

    if (!messageData || dispatchShow === "FALSE") {
      return null
    }

    function handleButtonClick() {
      setIsActive(!isActive)
    }

    return (
      <DispatchDetail>
        <SmallPara className="add-margin">
          {dispatchMessage
            ? dispatchMessage
            : "Tick to authorise for immediate dispatch. If unticked, you will receive a formal quote which you will then be able to accept."}
        </SmallPara>
        <div>
          <Checkbox onClick={handleButtonClick} className={`${isActive ? "active" : ""}`} />
        </div>
      </DispatchDetail>
    )
  }

  return (
    <>
      <TotalBoxContainer>
        <StyledTotalBox>
          <StyledHeader>Order summary</StyledHeader>
          <StyledPara>
            <span>{basketMessage}</span> <span>£{productsTotal.toFixed(2)}</span>
          </StyledPara>
          <DiscountTiersComponent discountTiers={discountTiers} setFinalDiscount={setFinalDiscount} />
          <DiscountComponent discountTiers={discountTiers} setFinalDiscount={setFinalDiscount} />
          <StyledHr />
          <RightContainer>
            <InnerRightContainer>
              <p>
                <span>
                  Amount due: £{(productsTotalWithoutDiscount * (1 - endDiscount * 0.01) + finalPostage).toFixed(2)}
                </span>
              </p>
              <SmallPara>(ex vat)</SmallPara>
              <StyledButton
                type="submit"
                value="Submit"
                onClick={handleSubmit}
                disabled={productsTotal === 0 || isDisabled}
                value="Submit order"
              />
            </InnerRightContainer>
          </RightContainer>
          <DispatchComponent messageData={messageData} />
          <div key="something">
            <AdditionalMessage
              messageData={messageData}
              dataToSend={dataToSend}
              message={message}
              setMessage={setMessage}
              additionalMessage={additionalMessage}
            />
          </div>
        </StyledTotalBox>
      </TotalBoxContainer>
      <StyledOverlay className={showOverlay ? "show" : ""}>
        <div>
          <StyledH2>
            {confirmationMessage
              ? confirmationMessage
              : "Thanks for your order. You should receive a confirmation via email soon."}
          </StyledH2>
          <StyledLink href="https://www.wzis.dog/">Back to site</StyledLink>
        </div>
      </StyledOverlay>
    </>
  )
}
export default TotalBox

const TotalBoxContainer = styled.div`
  width: 40%;
  max-width: 100%;
  padding-left: 20px;
  position: sticky;
  top: 20px;

  @media (max-width: 900px) {
    width: 100%;
    padding-left: 0;
    padding-top: 20px;
  }
`

const StyledTotalBox = styled.div`
  background-color: #1c242b;
  padding: 20px;
  color: #fff;
  width: 100%;
  max-width: 100%;
  position: sticky;
  top: 20px;
`
const StyledHeader = styled.h2`
  font-size: 20px;
  line-height: 30px;
  color: #fab528;
`

const StyledPara = styled.p`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const StyledButton = styled.input`
  background-color: #e4032d;
  color: #fff;
  padding: 10px 20px;
  border: none;
  outline: none;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
  transition: all ease-in-out 200ms;
  margin-top: 10px;
  right: 20px;
  bottom: 20px;
  width: 100%;
  min-width: 150px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #fab528;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`

const StyledLink = styled.a`
  background-color: #fab528;
  color: #e4032d;
  padding: 10px 20px;
  border: none;
  outline: none;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  max-width: 200px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: all ease-in-out 200ms;
  margin-top: 10px;
  align-self: center;
  text-align: center;

  &:hover {
    background-color: #fac353;
  }
`

const SmallPara = styled.p`
  font-size: 12px;
  margin-top: -12px;

  &.add-margin {
    margin-top: 0;
  }
`

const StyledItalic = styled.i`
  font-style: italic;
`

const StyledItalicSmall = styled.i`
  font-style: italic;
  font-size: 12px;
`

const StyledHr = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #fff;
  border: none;
`

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #e4032d;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-image: url(${HeroTwo});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  transition: all ease-in-out 300ms;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    width: 600px;
    text-align: center;
  }

  &.show {
    opacity: 1;
    pointer-events: initial;
  }
`

const StyledH2 = styled.h2`
  margin-top: -80px;
`

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const InnerRightContainer = styled.div`
  text-align: right;
`

const Checkbox = styled.button`
  width: 24px;
  min-height: 24px;
  display: block;
  background: transparent;
  border: solid 2px #fff;

  &:hover {
    cursor: pointer;
  }

  &.active {
    &:after {
      content: "\\2714";
      display: block;
      color: #fff;
    }
  }
`

const DispatchDetail = styled.div`
  display: flex;
  align-self: flex-end;
  width: auto;
  max-width: 80%;
  margin-left: auto;
  margin-top: 20px;
  justify-content: flex-end;

  p {
    margin-right: 10px;
  }
`
