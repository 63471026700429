export function removeUtcMedium(str) {
  const formattedString = str.substring(0, str.indexOf("&utm_medium=email"))
  return formattedString
}

export function removeSubstr(str, substr) {
  const formattedString = str.substring(0, str.indexOf(substr))
  return formattedString
}

export function formatCompanyName(str) {
  if (!str) {
    return null
  }

  let formattedString

  if (str.includes("&utm_medium=email")) {
    formattedString = removeUtcMedium(String(str))
    formattedString.replace("&", " & ")
  } else if (str.includes("& utm_medium")) {
    formattedString = removeSubstr(String(str), "& utm_medium")
  } else {
    formattedString = str
    formattedString.replace("&", " & ")
  }

  if (formattedString.includes("&utm")) {
    // Remove the utm parameters from the company name
    formattedString = formattedString.substring(0, formattedString.indexOf("&utm"))
  }

  formattedString.trim()
  return formattedString
}
