import React from "react"
import styled from "styled-components"
import _ from "lodash"

const TotalBox = (props) => {
  const { setCompanyDetails, companyDetails } = props
  // Customer form state

  // Get the company name
  let search = window.location.search
  let params = new URLSearchParams(search)
  let companyName = params.get("company")
  let newaccount = params.get("newaccount")
  let existingaccount = params.get("existingaccount")

  if (companyName) {
    return null
  }

  function handleChange(e, id) {
    setCompanyDetails({
      ...companyDetails,
      [id]: e.target.value
    })
  }

  return (
    <StyledCustomerForm>
      <h2>Company details</h2>
      <StyledForm>
        <StyledFieldset>
          <label htmlFor="company">Company name</label>
          <input
            type="text"
            placeholder="Company name"
            id="company"
            value={companyDetails.company}
            onChange={(e) => handleChange(e, "company")}
            required
          />
        </StyledFieldset>
        <StyledFieldset>
          <label htmlFor="contact">Contact name</label>
          <input
            type="text"
            placeholder="Contact name"
            id="contact"
            value={companyDetails.contact}
            onChange={(e) => handleChange(e, "contact")}
            required
          />
        </StyledFieldset>
        {newaccount && (
          <>
            <StyledFieldset>
              <label htmlFor="telephone">Telephone</label>
              <input
                type="text"
                placeholder="telephone"
                value={companyDetails.telephone}
                onChange={(e) => handleChange(e, "telephone")}
                required
              />
            </StyledFieldset>
            <StyledFieldset>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                placeholder="Email"
                id="email"
                value={companyDetails.email}
                onChange={(e) => handleChange(e, "email")}
                required
              />
            </StyledFieldset>
            <StyledFieldset>
              <label htmlFor="addressone">Address 1</label>
              <input
                type="text"
                placeholder="Address 1"
                id="addressone"
                value={companyDetails.addressone}
                onChange={(e) => handleChange(e, "addressone")}
                required
              />
            </StyledFieldset>
            <StyledFieldset>
              <label htmlFor="addresstwo">Address 2</label>
              <input
                type="text"
                placeholder="Address 2"
                id="addresstwo"
                value={companyDetails.addresstwo}
                onChange={(e) => handleChange(e, "addresstwo")}
                required
              />
            </StyledFieldset>
            <StyledFieldset>
              <label htmlFor="town">Town</label>
              <input
                type="text"
                placeholder="Town"
                id="town"
                value={companyDetails.town}
                onChange={(e) => handleChange(e, "town")}
                required
              />
            </StyledFieldset>
            <StyledFieldset>
              <label htmlFor="postcode">Postcode</label>
              <input
                type="text"
                placeholder="Postcode"
                id="postcode"
                value={companyDetails.postcode}
                onChange={(e) => handleChange(e, "postcode")}
                required
              />
            </StyledFieldset>
          </>
        )}
      </StyledForm>
      {newaccount && (
        <StyledFieldset>
          <label htmlFor="notes">Other notes</label>
          <input
            type="text"
            placeholder="Other notes"
            id="notes"
            value={companyDetails.notes}
            onChange={(e) => handleChange(e, "notes")}
            required
          />
        </StyledFieldset>
      )}
    </StyledCustomerForm>
  )
}
export default TotalBox

const StyledCustomerForm = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;

  label {
    color: #e4032d;
    margin-bottom: 10px;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
  }
`

const StyledForm = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  input {
    border: none;
    background-color: #efefef;
    padding: 12px 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
  }
`

const StyledFieldset = styled.div`
  display: flex;
  flex-direction: column;

  input {
    border: none;
    background-color: #efefef;
    padding: 12px 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
  }
`
