import React from "react"
import styled from "styled-components"
import _ from "lodash"
import ProductHeaderTable from "./ProductHeaderTable"

const Product = (props) => {
  const {
    data,
    index,
    setProductDetails,
    productDetails,
    productLabels,
    firstDiscountTier,
    pathName,
    activeCompanyAudience = ""
  } = props
  const id = data.Name
  const audience = _.get(data, "Audience", "").toLowerCase()

  if (data.Name && data.Audience && !data.RRP && !data["Pack QTY"]) {
    if (
      activeCompanyAudience ? !audience.includes(activeCompanyAudience.toLowerCase()) : !audience.includes(pathName)
    ) {
      return null
    }
    return <ProductHeaderTable productLabels={productLabels} id={id} />
  }

  const productQuantity = productDetails && productDetails[index] ? productDetails[index].quantity : 0

  const [details, setDetails] = React.useState({
    name: data.Name,
    price: data.RRP,
    packQTY: data["Pack QTY"],
    tradeCost: (data.RRP / 1.2) * (1 - firstDiscountTier * 0.01),
    quantity: productQuantity,
    additionalDiscount: parseInt(data["Additional discount"]),
    additionalDiscountValue: 0
  })

  const handleSubtract = () => {
    if (details.quantity === 0) {
      return false
    }

    setDetails((params) => ({
      ...params,
      quantity: details.quantity - parseInt(data["Pack QTY"]),
      additionalDiscountValue:
        details.quantity > 0
          ? (details.quantity - 1) *
            parseInt(data["Pack QTY"]) *
            parseInt(data.RRP / 1.2) *
            (parseInt(data["Additional discount"]) * 0.01)
          : 0
    }))
  }

  const handleAdd = () => {
    setDetails((params) => ({
      ...params,
      quantity: details.quantity + parseInt(data["Pack QTY"]),
      additionalDiscountValue:
        details.quantity >= 0
          ? (details.quantity + 1) *
            parseInt(data["Pack QTY"]) *
            parseInt(data.RRP / 1.2) *
            (parseInt(data["Additional discount"]) * 0.01)
          : 0
    }))
  }

  React.useEffect(() => {
    if (details) {
      if (productDetails[index] || productDetails.includes(details)) {
      } else {
        const newProductDetails = productDetails
        newProductDetails[index] = details
        setProductDetails(newProductDetails)
      }
    }
  }, [])

  React.useEffect(() => {
    if (details) {
      const newProductDetails = productDetails
      newProductDetails[index] = details
      setProductDetails(newProductDetails)
    }
  }, [details])

  if (
    !details || !details.name || !details.price || activeCompanyAudience
      ? !audience.includes(activeCompanyAudience.toLowerCase())
      : !audience.includes(pathName)
  ) {
    return null
  }

  return (
    <StyledProductContainer>
      <StyledProduct>
        <ProductName dangerouslySetInnerHTML={{ __html: details.name }} />
        <StyledCenteredP>{details.packQTY}</StyledCenteredP>
        <p>{`£${data.RRP}`}</p>
        <p>{`£${((data.RRP / 1.2) * (1 - firstDiscountTier * 0.01)).toFixed(2)}`}</p>
        <QuantityContainer>
          <StyledIcon onClick={handleSubtract}>-</StyledIcon>
          <p>{details.quantity}</p>
          <StyledIcon onClick={handleAdd}>+</StyledIcon>
        </QuantityContainer>
      </StyledProduct>
    </StyledProductContainer>
  )
}
export default Product

const ProductName = styled.p`
  padding-right: 20px;

  strong {
    font-weight: bold;
  }

  ul {
    padding: 0;
    list-style-position: inside;
  }

  span,
  a {
    color: #e4032d;
  }

  a {
    text-decoration: underline;
  }
`

const StyledProductContainer = styled.div`
  padding: 0 20px;
  background-color: #fff;
`

const StyledProduct = styled.div`
  align-items: center;
  width: 100%;
  color: #718393;
  padding-bottom: 20px;
  padding-top: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: ". . . .";
  border-bottom: solid 1px #e2e2e2;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const StyledIcon = styled.button`
  color: #e4032d;
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
  cursor: pointer;
  background: none;
  border: none;
`

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledCenteredP = styled.p`
  width: 60px;
  text-align: center;
`
