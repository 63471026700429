import React from "react"
import styled from "styled-components"

const ProductHeaderTable = (props) => {
  const { productLabels, id } = props

  return (
    <StyledProductHeader>
      <h2>{id}</h2>
      <StyledProductTable>
        <p>{productLabels["Item name"]}</p>
        <p>{productLabels["Pack QTY"]}</p>
        <p>{productLabels["RRP"]}</p>
        <p>{productLabels["Trade cost"]}</p>
        <p>{productLabels["Quantity"]}</p>
      </StyledProductTable>
    </StyledProductHeader>
  )
}
export default ProductHeaderTable

const StyledProductHeader = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 0;
`

const StyledProductTable = styled.div`
  color: #e4032d;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: ". . . .";
  border-bottom: solid 1px #e2e2e2;

  p {
    color: #e4032d;
    margin-bottom: 10px;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
  }
`
