import React from "react"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import _ from "lodash"
import { formatCompanyName } from "./utils/utilities"
import useGoogleSheets from "use-google-sheets"
import Product from "./components/Product"
import TotalBox from "./components/TotalBox"
import CustomerForm from "./components/CustomerForm"

// Images
import HeroImage from "./images/hero.webp"
import Logo from "./images/logo.png"

const SPREADSHEET_ID = "1kb4ruk-LRFC31_ZcjrMDwXYnEbtF1XY3YVgLIvEveig"
const API_KEY = "AIzaSyCC81SOYvab29JBkBQessQkg2jsu4wicd4"

const App = () => {
  const { data, loading, error } = useGoogleSheets({
    apiKey: API_KEY,
    sheetId: SPREADSHEET_ID
  })
  const [discounts, setDiscounts] = React.useState([])
  const [postage, setPostage] = React.useState([])
  const [confirmation, setConfirmation] = React.useState()
  const [productDetails, setProductDetails] = React.useState([])
  const [companyDetails, setCompanyDetails] = React.useState({})
  const [useForm, setUseForm] = React.useState(true)
  const [showOverlay, setShowOverlay] = React.useState(false)
  const [productLabels, setProductLabels] = React.useState()
  const [firstDiscountTier, setFirstDiscountTier] = React.useState()
  const [messageData, setMessageData] = React.useState()
  const [companyFilter, setCompanyFilter] = React.useState()
  const [activeCompanyAudience, setActiveCompanyAudience] = React.useState()
  const [existingCompany, setExistingCompany] = React.useState(false)
  let pathName = window.location.pathname.replace(/\//g, "") || "trade"

  function capitaliseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  // Capitalise the first letter of our audience.
  const audiencePath = capitaliseFirstLetter(pathName)

  React.useEffect(() => {
    data.map((item, index) => {
      if (item.id === "Discounts") {
        setDiscounts(item.data)

        if (audiencePath && _.get(item.data[0], audiencePath)) {
          setFirstDiscountTier(item.data[0][audiencePath])
        } else {
          setFirstDiscountTier(item.data[0]["Discount percentage"])
        }
      }
      if (item.id === "Postage") {
        setPostage(item.data)
      }
      if (item.id === "Confirmation") {
        setConfirmation(item.data[0])
      }
      if (item.id === "Labels") {
        setProductLabels(item.data[0])
      }
      if (item.id === "Message") {
        setMessageData(item.data[0])
      }
      if (item.id === "Companies") {
        setCompanyFilter(item.data)
      }
    })
  }, [data])

  // Get the company name
  let search = window.location.search
  let params = new URLSearchParams(search)

  if (search.includes("&")) {
    window.location.href = `/${search.replace(/&/, "%26")}`
  }

  let companyName = params.get("company")
  let newaccount = params.get("newaccount")
  let existingaccount = params.get("existingaccount")

  // Sets the active audience based on the companies tab in Google sheets.
  if (companyName && !activeCompanyAudience && existingCompany === false) {
    companyFilter &&
      companyFilter.map((item) => {
        if (item["Company name"] === formatCompanyName(companyName)) {
          setActiveCompanyAudience(item["Audience"])
          setExistingCompany(true)
        }
      })
  }

  React.useEffect(() => {
    if (companyName) {
      setUseForm(false)

      // If the company is under 4 characters, is refUk or blank, redirect
      if (companyName.length < 4 || companyName === "refUk") {
        window.location.href = "/?existingaccount=1"
      }
    }
    if (!companyName && !newaccount && !existingaccount) {
      window.location.href = "https://wzis.dog/"
    }
  }, [])

  if (loading) {
    return (
      <ThemeProvider theme={{}}>
        <GlobalStyle whiteColor />
      </ThemeProvider>
    )
  }

  if (error) {
    return (
      <ThemeProvider theme={{}}>
        <GlobalStyle whiteColor />
        <div>Error!</div>
      </ThemeProvider>
    )
  }

  return (
    <>
      <ThemeProvider theme={{}}>
        {loading && <div>Loading...</div>}
        {error && <div>Error...</div>}
        <Wrapper className={showOverlay ? "locked" : ""}>
          <GlobalStyle whiteColor />
          <StyledLogoContainer href="https://www.wzis.dog/">
            <StyledLogo src={Logo} alt="logo" />
          </StyledLogoContainer>
          {useForm === false && (
            <h2>
              Shop name: <StyledSpan>{formatCompanyName(companyName)}</StyledSpan>
            </h2>
          )}
          <AppContainer className="01">
            <StyledLeftContainer>
              <div>
                {useForm && <CustomerForm setCompanyDetails={setCompanyDetails} companyDetails={companyDetails} />}
                {data.map((item, index) => {
                  const arrayLength = item.length
                  if (item.id === "Products") {
                    return item.data.map((item, index) => {
                      return (
                        <Product
                          data={item}
                          key={item.Name + Math.random() * 100}
                          index={index}
                          arrayLength={arrayLength}
                          setProductDetails={setProductDetails}
                          productDetails={productDetails}
                          productLabels={productLabels}
                          firstDiscountTier={firstDiscountTier}
                          pathName={pathName}
                          activeCompanyAudience={activeCompanyAudience}
                        />
                      )
                    })
                  }
                })}
              </div>
            </StyledLeftContainer>
            <TotalBox
              setProductDetails={setProductDetails}
              productDetails={productDetails}
              discounts={discounts}
              postage={postage}
              useForm={useForm}
              companyDetails={companyDetails}
              showOverlay={showOverlay}
              setShowOverlay={setShowOverlay}
              confirmation={confirmation}
              firstDiscountTier={firstDiscountTier}
              messageData={messageData}
              audiencePath={audiencePath}
              activeCompanyAudience={activeCompanyAudience}
              existingCompany={existingCompany}
              companyFilter={companyFilter}
            />
          </AppContainer>
        </Wrapper>
      </ThemeProvider>
    </>
  )
}
export default App

const StyledLeftContainer = styled.div`
  display: grid;
  width: 60%;
  max-width: 100%;
  margin-top: -20px;

  @media (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
`

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  &.locked {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
`

const AppContainer = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`

const StyledSpan = styled.span`
  color: #e4032d;
`

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    background-color: #fab528;
    color: #1C242B;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    height: 100%;
    margin: 0;
    background-image: url(${HeroImage});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  textarea {
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
  }
`

const StyledLogoContainer = styled.a`
  display: flex;
  padding-bottom: 20px;
`

const StyledLogo = styled.img`
  width: 80px;
  height: 80px;
  margin: 0 auto;
  align-self: center;
`
